<template>
    <div class="card p-col-12">
        <div class="exception-body notfound card" :style="{ minHeight: 100 + '%' }">
            <img src="assets/layout/images/logo-white.svg" alt="diamond-layout" class="logo" />

            <div class="exception-content">
                <div class="exception-title">NOT FOUND</div>
                <div class="exception-detail">Запрошенная Вами страница не найдена</div>
                <router-link to="/">Вернутся на главную</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>
